const observer = new IntersectionObserver(callback)
const video = document.querySelectorAll('[data-js-video-autoplay]')

if (video.length) {
  initVideo(video)
}

function callback (entries, observer) {
  entries.forEach((entry) => {
    if (entry.target.error !== null) {
      return
    }

    if (entry.isIntersecting) {
      entry.target.setAttribute('autoplay', 'autoplay')
      entry.target.play().catch((e) => {})
    } else {
      if (entry.target.paused === false) {
        entry.target.pause()
      }
    }
  })
}

function initVideo (video) {
  video.forEach(video => {
    observer.observe(video)
  }, false)
}
