import * as Sentry from '@sentry/browser'

if (process.env.PRODUCTION) {
  Sentry.init({
    dsn: 'https://adfc45f689e141fbbcea41b4c977eedf@log.move-elevator.de/142',
    integrations: [
      Sentry.browserTracingIntegration({}),
      Sentry.contextLinesIntegration()
    ],
    release: `release-${process.env.SENTRY_RELEASE}`,
    environment: 'production',
    ignoreErrors: [
      'AbortError: The operation was aborted.',
      'ResizeObserver loop limit exceeded',
      'window.lintrk is not a function',
      '_tfa is not defined',
      'obApi is not defined',
      '__rpta is not defined',
      'Can\'t find variable: obApi',
      'Can\'t find variable: _tfa',
      'Can\'t find variable: __rpta',
      'Failed to fetch',
      'jb'
    ],
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /www\.googletagmanager\.com/i,
      /phantasialand\.epccm19\.com/i
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1
  })
}
