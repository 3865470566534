// In this file you can import assets like images or stylesheets

import './images/import.js'
import './videos/import.js'

import './styles/app.scss'

import './scripts/feature-detection.js'
import './scripts/js-enabled.js'
import './scripts/toggle-fieldset.js'
import './scripts/element-intersection-observer.js'
import './scripts/video-autoplay.js'
import './scripts/popover.js'
import './scripts/hamburger.js'
import './scripts/card-select.js'
import './scripts/accordion.js'
import './scripts/masonry.js'
import './scripts/page-scrolled.js'
import './scripts/toggle-button-groups.js'
import './scripts/menu.js'
import './scripts/modal.js'
import { initSoftScroll } from './scripts/soft-scroll.js'
import { sectionMenu } from './scripts/section-menu.js'
import { initCookieLayer } from './scripts/cookie-layer.js'
import { initExitIntentPopup } from './scripts/exit-intent-popup'
import { initPromotionBanner } from './scripts/promotion-banner'

initCookieLayer()
sectionMenu()
initExitIntentPopup()
initPromotionBanner()
initSoftScroll()
