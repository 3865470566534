export const initSoftScroll = function () {
  const scrollTo = (element) => {
    window.scroll({
      behavior: 'smooth',
      left: 0,
      top: element.offsetTop
    })
    const previousTabIndex = element.getAttribute('tabindex')
    element.setAttribute('tabindex', '-1')
    element.focus({ preventScroll: true })
    if (previousTabIndex !== null) {
      element.setAttribute('tabindex', previousTabIndex)
    } else {
      element.removeAttribute('tabindex')
    }
  }

  const anchorLinks = document.querySelectorAll("a[href*='#']:not([href='#'])")

  anchorLinks.forEach(anchorLink => {
    anchorLink.addEventListener('click', function (event) {
      if (window.location.pathname.replace(/^\//, '') === event.currentTarget.pathname.replace(/^\//, '') &&
          window.location.hostname === event.currentTarget.hostname) {
        const target = document.querySelector(event.currentTarget.getAttribute('href'))

        if (target) {
          event.preventDefault()
          scrollTo(target)
        }
      }
    })
  })
}
